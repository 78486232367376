import CompanyDefinition, {
    AddressField,
    BadgerObjectDefinition,
    BaseField,
    DateTimeField,
    Field,
    FieldType,
    LinkDefinition,
    LookUpField,
    MainRelatedObject,
    NumericField,
    PickListField,
    PickListOption,
    TextField,
} from '../../../domain/CompanyDefinition/CompanyDefinition';

const mapFromAPI = (rawCompanyDefinition: any): CompanyDefinition => {
    const badgerObjectDefinitions = mapBadgerObjectDefinitions(rawCompanyDefinition.badger_object_definitions);
    const linkDefinitions = mapLinkDefinitions(rawCompanyDefinition.link_definitions);
    return {
        badgerObjectDefinitions,
        linkDefinitions,
    };
};

const mapBadgerObjectDefinitions = (rawBadgerObjectDefinitions: any): BadgerObjectDefinition[] => {
    return rawBadgerObjectDefinitions.map((rawBadgerObjectDefinition: any) => ({
        id: rawBadgerObjectDefinition.id,
        name: rawBadgerObjectDefinition.name,
        namePlural: rawBadgerObjectDefinition.name_plural,
        label: rawBadgerObjectDefinition.label,
        labelPlural: rawBadgerObjectDefinition.label_plural,
        isRoot: rawBadgerObjectDefinition.is_root,
        order: rawBadgerObjectDefinition.order,
        fields: mapFields(rawBadgerObjectDefinition.fields),
        mainRelatedObject: mapMainRelatedObject(rawBadgerObjectDefinition.main_related_object),
    }));
};

const mapFields = (rawFields: any): Field[] => {
    return rawFields.map((rawField: any) => {
        switch (rawField.type) {
            case FieldType.TEXT:
                return mapTextField(rawField);
            case FieldType.NUMBER:
                return mapNumericField(rawField);
            case FieldType.PICK_LIST:
                return mapPickListField(rawField);
            case FieldType.ADDRESS:
                return mapAddressField(rawField);
            case FieldType.DATE_TIME:
                return mapDateTimeField(rawField);
            case FieldType.LOOK_UP:
                return mapLookUpField(rawField);
            default:
                console.error(`Unknown field type: ${rawField.type}`);
                return {};
        }
    });
};

const mapMainRelatedObject = (rawMainRelatedObject: any): MainRelatedObject | undefined => {
    if (rawMainRelatedObject) {
        return {
            relatedObjectType: rawMainRelatedObject.badger_object_definition,
            lookUpField: rawMainRelatedObject.lookup_field,
        };
    }
    return {
        relatedObjectType: '',
        lookUpField: '',
    };
};

const mapBaseField = (rawField: any): BaseField => ({
    id: rawField.id,
    name: rawField.name,
    label: rawField.label,
    isMandatory: rawField.is_mandatory,
    isSearchable: rawField.is_searchable,
    isFilterable: rawField.is_filterable,
    isColorizable: rawField.is_colorizable,
    isViewOnly: rawField.is_view_only,
    displayOrder: rawField.display_order,
    sortingOrder: rawField.sorting_order,
    sortingOrderDirection: rawField.sorting_order_direction,
    displayType: rawField.display_type,
});

const mapTextField = (rawField: any): TextField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.TEXT,
        isTextArea: rawField.is_text_area,
        maxLength: rawField.max_length,
    };
};

const mapNumericField = (rawField: any): NumericField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.NUMBER,
        minValue: rawField.min_value,
        maxValue: rawField.max_value,
        decimals: rawField.decimals,
    };
};

const mapPickListField = (rawField: any): PickListField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.PICK_LIST,
        options: mapPickListOptions(rawField.options),
    };
};

const mapPickListOptions = (rawPickListOptions: any): PickListOption[] => {
    return rawPickListOptions.map((rawPickListOption: any) => ({
        id: rawPickListOption.id,
        label: rawPickListOption.label,
        value: rawPickListOption.value,
        order: rawPickListOption.order,
    }));
};

const mapAddressField = (rawField: any): AddressField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.ADDRESS,
        isMain: rawField.is_main,
        accuracyTypes: rawField.accuracy_types,
    };
};

const mapDateTimeField = (rawField: any): DateTimeField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.DATE_TIME,
    };
};

const mapLookUpField = (rawField: any): LookUpField => {
    return {
        ...mapBaseField(rawField),
        type: FieldType.LOOK_UP,
        relatedObjectType: rawField.related_object_type,
    };
};

const mapLinkDefinitions = (rawLinkDefinitions: any): LinkDefinition[] => {
    return rawLinkDefinitions.map((rawLinkDefinition: any) => ({
        id: rawLinkDefinition.id,
        order: rawLinkDefinition.order,
        sourceDefinitionNamePlural: rawLinkDefinition.source_definition_name_plural,
        targetDefinitionNamePlural: rawLinkDefinition.target_definition_name_plural,
        isTargeMain: rawLinkDefinition.is_target_main,
    }));
};

const mapToAPI = (companyDefinition: CompanyDefinition): any => {
    return {
        badger_object_definitions: mapBadgerObjectDefinitionsToAPI(companyDefinition.badgerObjectDefinitions),
        link_definitions: mapLinkDefinitionsToAPI(companyDefinition.linkDefinitions),
    };
};

const mapBadgerObjectDefinitionsToAPI = (badgerObjectDefinitions: BadgerObjectDefinition[]): any => {
    return badgerObjectDefinitions.map((badgerObjectDefinition: BadgerObjectDefinition) => ({
        id: badgerObjectDefinition.id,
        name: badgerObjectDefinition.name,
        name_plural: badgerObjectDefinition.namePlural,
        label: badgerObjectDefinition.label,
        label_plural: badgerObjectDefinition.labelPlural,
        is_root: badgerObjectDefinition.isRoot,
        order: badgerObjectDefinition.order,
        main_related_object: badgerObjectDefinition.mainRelatedObject
            ? mapMainRelatedObjectToAPI(badgerObjectDefinition.mainRelatedObject)
            : undefined,
        fields: mapFieldsToAPI(badgerObjectDefinition.fields),
    }));
};

const mapFieldsToAPI = (fields: Field[]): any => {
    return fields.map((field: Field) => {
        switch (field.type) {
            case FieldType.TEXT:
                return mapTextFieldToAPI(field as TextField);
            case FieldType.NUMBER:
                return mapNumericFieldToAPI(field as NumericField);
            case FieldType.PICK_LIST:
                return mapPickListFieldToAPI(field as PickListField);
            case FieldType.ADDRESS:
                return mapAddressFieldToAPI(field as AddressField);
            case FieldType.DATE_TIME:
                return mapDateTimeFieldToAPI(field as DateTimeField);
            case FieldType.LOOK_UP:
                return mapLookUpFieldToAPI(field as LookUpField);
        }
    });
};

const mapBaseFieldToAPI = (field: BaseField): any => {
    return {
        id: field.id,
        name: field.name,
        label: field.label,
        is_mandatory: field.isMandatory,
        is_searchable: field.isSearchable,
        is_filterable: field.isFilterable,
        is_colorizable: field.isColorizable,
        is_view_only: field.isViewOnly,
        display_order: field.displayOrder,
        sorting_order: field.sortingOrder,
        sorting_order_direction: field.sortingOrderDirection,
        display_type: field.displayType,
    };
};

const mapTextFieldToAPI = (textField: TextField): any => {
    const baseField = mapBaseFieldToAPI(textField);
    return {
        ...baseField,
        type: FieldType.TEXT,
        is_text_area: textField.isTextArea,
        max_length: textField.maxLength,
    };
};

const mapNumericFieldToAPI = (numericField: NumericField): any => {
    const baseField = mapBaseFieldToAPI(numericField);
    return {
        ...baseField,
        type: FieldType.NUMBER,
        min_value: numericField.minValue,
        max_value: numericField.maxValue,
        decimals: numericField.decimals,
    };
};

const mapPickListFieldToAPI = (pickListField: PickListField): any => {
    const baseField = mapBaseFieldToAPI(pickListField);
    return {
        ...baseField,
        type: FieldType.PICK_LIST,
        options: mapPickListOptionsToAPI(pickListField.options),
    };
};

const mapPickListOptionsToAPI = (pickListOptions: PickListOption[]): any => {
    return pickListOptions.map((pickListOption: PickListOption) => ({
        id: pickListOption.id,
        label: pickListOption.label,
        value: pickListOption.value,
        order: pickListOption.order,
    }));
};

const mapAddressFieldToAPI = (addressField: AddressField): any => {
    const baseField = mapBaseFieldToAPI(addressField);
    return {
        ...baseField,
        type: FieldType.ADDRESS,
        is_main: addressField.isMain,
        accuracy_types: addressField.accuracyTypes,
    };
};

const mapDateTimeFieldToAPI = (dateTimeField: DateTimeField): any => {
    const baseField = mapBaseFieldToAPI(dateTimeField);
    return {
        ...baseField,
        type: FieldType.DATE_TIME,
    };
};

const mapLookUpFieldToAPI = (lookUpField: LookUpField): any => {
    const baseField = mapBaseFieldToAPI(lookUpField);
    return {
        ...baseField,
        type: FieldType.LOOK_UP,
        related_object_type: lookUpField.relatedObjectType,
        is_mro: false,
    };
};

const mapMainRelatedObjectToAPI = (mainRelatedObject: MainRelatedObject): any => {
    return {
        badger_object_definition: mainRelatedObject?.relatedObjectType,
        lookup_field: mainRelatedObject?.lookUpField,
    };
};

const mapLinkDefinitionsToAPI = (linkDefinitions: LinkDefinition[]): any => {
    return linkDefinitions.map((linkDefinition: LinkDefinition) => ({
        id: linkDefinition.id,
        order: linkDefinition.order,
        source_definition_name_plural: linkDefinition.sourceDefinitionNamePlural,
        target_definition_name_plural: linkDefinition.targetDefinitionNamePlural,
        is_target_main: linkDefinition.isTargeMain,
    }));
};

export { mapFromAPI, mapToAPI };
