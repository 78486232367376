import { useMultiUserSelectorControllerType } from './hooks/useMultiUserSelectorController';
import ModalSelector, { ModalSelectorObject } from '../../common/components/ModalSelector';
import { useEffect, useState } from 'react';

export interface MultiUserSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    useController: useMultiUserSelectorControllerType;
    selectLabel: string;
    selectedLabel: string;
    description: string;
}

const MultiUserSelector = ({
    visible,
    onConfirm,
    onClose,
    useController,
    selectLabel,
    selectedLabel,
    description,
}: MultiUserSelectorProps) => {
    const { users, selectedCrmUsersIdsInSelector, loading, handleOnUserClick, handleOnConfirm } = useController();
    const [searchValue, setSearchValue] = useState('');
    const [filteredUsers, setFilteredUsers] = useState(users);

    useEffect(() => {
        if (searchValue.length > 0) {
            const lowerCaseSearchValue = searchValue.toLowerCase();
            const filtered = users.filter(
                (user) =>
                    `${user.firstName} ${user.lastName}`.toLowerCase().includes(lowerCaseSearchValue) ||
                    user.email.toLowerCase().includes(lowerCaseSearchValue)
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users);
        }
    }, [searchValue, users]);

    const objects = filteredUsers.map(
        (user) =>
            ({
                id: user.crmUserId,
                primaryText: `${user.firstName} ${user.lastName}`,
                secondaryText: user.email,
                isExcluded: user.isIntegrated,
            }) as ModalSelectorObject
    );

    return (
        <ModalSelector
            visible={visible}
            onConfirm={onConfirm}
            onClose={onClose}
            objects={objects}
            selectedObjectIds={selectedCrmUsersIdsInSelector}
            loading={loading}
            handleOnObjectClick={handleOnUserClick}
            handleOnConfirm={handleOnConfirm}
            modalTitle="List of CRM users"
            modalSubtitle="Select users"
            objectDescription={description}
            uncheckedLabel={selectLabel}
            checkedLabel={selectedLabel}
            primaryButtonCaption={'Confirm'}
            searchBar={true}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
        />
    );
};

export default MultiUserSelector;
