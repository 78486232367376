import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanyDefinitionRepository } from '../../../common/hooks/useCompanyDefinitionRepository';
import {
    AccuracyType,
    BadgerObjectDefinition,
    DisplayType,
    FieldType,
} from '../../../domain/CompanyDefinition/CompanyDefinition';
import { ROW_COLUMN_SEPARATOR } from '../defs';
import useFormController from './hooks/useFormController';

import {
    ConfirmationProps,
    useUnsavedChangesPrompt,
} from '../../../common/hooks/useUnsavedChangesPrompt/useUnsavedChangesPrompt';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { editCompanyDefinition } from '../../../store/slices/companySlice/thunks';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';

import useColumnsVisibility from './hooks/useColumnsVisibility';
import useRows from './hooks/useRows';
import { v4 as uuidv4 } from 'uuid';
import { ActionType, addMessage, buildSnackbarMessage, MessageSeverity } from '../../../store/slices/feedbackSlice';
import { selectIsSaving } from '../../../store/slices/companySlice/companySlice';
import { defaultFields, getValidProperties, propertyMapper } from './utils';
import useVerticalScrollingListener from './hooks/useVerticalScrollingListener';

export type HandleInputChange = (
    fieldId: string,
    attribute: string,
    value: number | string | boolean | object | null
) => void;

export type HandleColumnsVisibilityChange = (columnName: string, visible: boolean) => void;

const DEFAULT_ADDRESS_ACCURACY_TYPES = [AccuracyType.ROOFTOP, AccuracyType.RANGE_INTERPOLATED];

const deepCopy = (object: any) => JSON.parse(JSON.stringify(object));

export const useObjectDefinitionContentController = () => {
    const { object } = useParams();
    const dispatch = useAppDispatch();
    const savingChanges = useAppSelector(selectIsSaving);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirm, setConfirm] = useState<ConfirmationProps | null>(null);
    const [resyncNeeded, setResyncNeeded] = useState(false);
    const [objectDefinition, setObjectDefinition] = useState<BadgerObjectDefinition | undefined>(undefined);
    const { companyDefinition } = useCompanyDefinitionRepository();
    const { selectedCompanyId } = useSelectCompany();
    const [showColumnsVisibilityEditor, setShowColumnsVisibilityEditor] = useState(false);

    const {
        buildDefaultValues,
        getValues,
        isDirty,
        isValid,
        handleSubmit,
        control,
        watch,
        addRowValues,
        updateRowValues,
        deleteRowValues,
        changeOrder,
        setViewOnlyExclusion,
        fields,
        mainAddressField,
    } = useFormController();

    const scrollingContainerRef = useRef<HTMLDivElement>(null);
    const fieldsParametersTitleRef = useRef<HTMLDivElement>(null);
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const { scrollTop } = useVerticalScrollingListener(scrollingContainerRef.current);
    const [tableTopOffset, setTableTopOffset] = useState(0);
    const [tableScrollOffset, setTableScrollOffset] = useState(0);

    useEffect(() => {
        if (!fieldsParametersTitleRef || !tableContainerRef) return;

        const scrollingContainerOffsetTop = scrollingContainerRef.current?.offsetTop || 0;
        const tableTitleHeight = fieldsParametersTitleRef.current?.offsetHeight || 0;
        const tableOffset = tableContainerRef.current?.offsetTop || 0;
        const margin = tableOffset - scrollingContainerOffsetTop;
        setTableTopOffset(tableTitleHeight + margin);
    }, [scrollTop]);

    useEffect(() => {
        let newScrollOffset = scrollTop - tableTopOffset;
        if (newScrollOffset < 0) {
            newScrollOffset = 0;
        }
        setTableScrollOffset(newScrollOffset);
    }, [scrollTop, tableTopOffset]);

    const { columns, updateVisibleColumns, setColumnVisibility, setConditionalColumnsVisibility } =
        useColumnsVisibility();

    const { rows, updateRow, updateRows, resetRows, addRow, deleteRow, addedFields } = useRows(objectDefinition);

    useEffect(() => {
        if (!companyDefinition) {
            return;
        }
        setObjectDefinition(
            companyDefinition?.badgerObjectDefinitions.find(
                (badgerObjectDefinition: BadgerObjectDefinition) => badgerObjectDefinition.namePlural === object
            )
        );
    }, [companyDefinition, object]);

    useEffect(() => {
        resetState();
    }, [objectDefinition]);

    useEffect(() => {
        const hasMainAddress = Boolean(mainAddressField);
        setConditionalColumnsVisibility(hasMainAddress);
    }, [mainAddressField]);

    const types = watch(addedFields.map((uuid) => `${uuid}${ROW_COLUMN_SEPARATOR}type`));

    useEffect(() => {
        types.forEach((type, index) => {
            if (type === undefined || type === null) {
                return;
            }
            refreshRow(type, addedFields[index]);
        });
    }, [types]);
    const openConfirmationDialog = useCallback(
        (confirm: ConfirmationProps) => {
            setConfirm(confirm);
            setConfirmationDialogOpen(true);
        },
        [setConfirmationDialogOpen]
    );

    useUnsavedChangesPrompt({ isDirty, openConfirmationDialog });

    const resetState = () => {
        const hasMainAddress = Boolean(objectDefinition?.fields?.find((field) => field.isMain));
        setConditionalColumnsVisibility(hasMainAddress);
        const updatedColumns = updateVisibleColumns(hasMainAddress);
        const updatedRows = resetRows();
        buildDefaultValues(updatedColumns, updatedRows, objectDefinition);
        setResyncNeeded(false);
    };

    const handleMandatoryViewOnlyExclusion = (fieldUuid: string, attribute: string, value: boolean) => {
        const updatedRows = [...(rows ?? [])];
        const row = updatedRows.find((row) => row.uuid === fieldUuid);
        if (row) {
            const baseName = `${fieldUuid}${ROW_COLUMN_SEPARATOR}`;
            if (attribute === 'isMandatory' && value) {
                row.isViewOnly = false;
                setViewOnlyExclusion(baseName, 'isViewOnly');
            } else if (attribute === 'isViewOnly' && value) {
                row.isMandatory = false;
                setViewOnlyExclusion(baseName, 'isMandatory');
            }
        }
        updateRows(updatedRows);
    };

    const deleteField = (uuid: string) => {
        deleteRow(uuid);
        deleteRowValues(`${uuid}${ROW_COLUMN_SEPARATOR}`, uuid);
    };

    const reorder = (from: number, to: number) => {
        const updatedRows = [...(rows ?? [])];
        const [movedRow] = updatedRows.splice(from, 1);
        updatedRows.splice(to, 0, movedRow);
        updatedRows.forEach((row, index) => {
            const order = index + 1;
            row.displayOrder = order;
            changeOrder(`${row.uuid}${ROW_COLUMN_SEPARATOR}`, order);
            handleInputChange(row.uuid, 'displayOrder', order);
        });
        updateRows(updatedRows);
    };

    const refreshRow = (type: string, uuid: string) => {
        const previousType = rows?.find((row) => row.uuid === uuid)?.type;
        if (previousType !== type) {
            const values = {
                isMandatory: false,
                isSearchable: false,
                isColorizable: type === FieldType.PICK_LIST ? false : null,
                isFilterable:
                    type === FieldType.PICK_LIST || type === FieldType.NUMBER || type === FieldType.DATE_TIME
                        ? false
                        : null,
                isTextArea: type === FieldType.TEXT ? false : null,
                isViewOnly: false,
                pickList: type === FieldType.PICK_LIST ? [] : null,
                decimals: type === FieldType.NUMBER ? 0 : null,
                isMain: false,
                relatedObjectType: type === FieldType.LOOK_UP ? '' : null,
                addressAccuracyTypes: type === FieldType.ADDRESS ? DEFAULT_ADDRESS_ACCURACY_TYPES : null,
            };
            updateRowValues(`${uuid}${ROW_COLUMN_SEPARATOR}`, values);
            updateRow({ ...values, type }, uuid);
        }
    };

    const addField = () => {
        const uuid = uuidv4();
        const displayOrder =
            (rows
                ?.map((field) => field.displayOrder)
                .sort((a, b) => a - b)
                .pop() || 0) + 1;

        addRow(uuid, displayOrder);
        addRowValues(`${uuid}${ROW_COLUMN_SEPARATOR}`, displayOrder);
    };

    const dispatchSyncNeededMessage = () => {
        const needSyncMessage = buildSnackbarMessage(
            "Remember to sync this company's data in the Sync Data section",
            MessageSeverity.INFO,
            ActionType.NAVIGATE,
            `/companies/${selectedCompanyId}/sync-data`,
            'Go to Sync Data'
        );
        dispatch(addMessage(needSyncMessage));
    };

    const closeConfirmationDialog = () => {
        setConfirmationDialogOpen(false);
        confirm?.onCancel?.();
    };

    const discardChangesFromRouteBlockerPopup = () => {
        confirm?.onConfirm?.();
        setConfirmationDialogOpen(false);
    };

    const saveChanges = () => {
        handleSubmit(async (data) => {
            if (selectedCompanyId) {
                let rowIdsMapper: any = {};
                if (rows) {
                    for (const row of rows) {
                        rowIdsMapper[row.uuid] = { id: row.id, type: row.type };
                    }
                }

                let fieldsRegister: any = {};
                for (const key in data) {
                    const parts = key.split(ROW_COLUMN_SEPARATOR);
                    if (parts.length > 1) {
                        const details = rowIdsMapper[parts[0]];
                        if (!details) {
                            continue;
                        }
                        const type = details.type;
                        const validProperties = getValidProperties(type);
                        if (!fieldsRegister[parts[0]]) {
                            fieldsRegister[parts[0]] = {
                                ...defaultFields[type],
                                id: details.id,
                            };
                        }

                        const property = propertyMapper[parts[1]] || parts[1];
                        if (!validProperties.includes(property)) {
                            continue;
                        }
                        fieldsRegister[parts[0]] = {
                            ...fieldsRegister[parts[0]],
                            [property]: [null, undefined].includes(data[key])
                                ? defaultFields[type][property]
                                : data[key],
                        };
                    }
                }

                data.title.forEach((item: string) => {
                    fieldsRegister[item].displayType = DisplayType.AS_TITLE;
                });

                data.subtitle.forEach((item: string) => {
                    fieldsRegister[item].displayType = DisplayType.AS_SUBTITLE;
                });

                fieldsRegister[data.sortingOrder].sortingOrder = 1;
                fieldsRegister[data.sortingOrder].sortingOrderDirection = data.orderDirection;

                data.searchCriteria.forEach((item: string) => {
                    fieldsRegister[item].isSearchable = true;
                });

                const mainAddress = data.mainAddress;
                if (mainAddress) {
                    fieldsRegister[mainAddress].isMain = true;
                }

                const updatedCompanyDefinition = deepCopy(companyDefinition);
                let updatedObjectDefinition = updatedCompanyDefinition?.badgerObjectDefinitions?.find(
                    (badgerObjectDefinition: BadgerObjectDefinition) => badgerObjectDefinition.namePlural === object
                );
                updatedObjectDefinition.fields = Object.values(fieldsRegister);
                updatedObjectDefinition.isRoot = data.isRoot;
                updatedObjectDefinition.mainRelatedObject = data.mainRelatedObject;

                const result = await dispatch(
                    editCompanyDefinition({
                        companyId: selectedCompanyId,
                        companyDefinition: updatedCompanyDefinition,
                    })
                );
                if (result.payload) {
                    console.log(result.payload);
                } else {
                    console.log(result.error);
                }
            }
        })();
        confirm?.onConfirm?.();
        setConfirm(null);
        setConfirmationDialogOpen(false);
        if (resyncNeeded) {
            dispatchSyncNeededMessage();
        }
    };

    const isPicklistLabelValueChange = (attribute: string) => attribute === 'options';
    const setResyncNeededIfChangeNeedsResync = (attribute: string) => {
        if (columns.find((column) => column.name === attribute)?.needsResync || isPicklistLabelValueChange(attribute)) {
            setResyncNeeded(true);
        }
    };
    const handleInputChange: HandleInputChange = (fieldUuid, attribute, value) => {
        setResyncNeededIfChangeNeedsResync(attribute);
        if (['isMandatory', 'isViewOnly'].includes(attribute)) {
            handleMandatoryViewOnlyExclusion(fieldUuid, attribute, Boolean(value));
        }
    };

    const handleMultiPicklistChange = (attribute: string) => {
        setResyncNeededIfChangeNeedsResync(attribute);
    };

    const handleColumnsVisibilityChange: HandleColumnsVisibilityChange = (columnName: string, visible: boolean) => {
        setColumnVisibility(columnName, visible);
    };

    const handleOnColumnsVisibilityEditorClick = (open: boolean) => {
        setShowColumnsVisibilityEditor(open);
    };

    const buildLookUpOptions = () => {
        return companyDefinition?.badgerObjectDefinitions.map((objectDefinition) => {
            return {
                label: objectDefinition.label,
                value: objectDefinition.namePlural,
                uuid: objectDefinition.namePlural,
            };
        });
    };

    const buildMROOptions = () => {
        const filteredObjectDefinitions = companyDefinition?.badgerObjectDefinitions.filter(
            (filteredObjectDefinition) =>
                filteredObjectDefinition.fields.some(
                    (field) =>
                        field.type === FieldType.LOOK_UP && field.relatedObjectType === objectDefinition?.namePlural
                )
        );

        const options = [];
        for (const filteredObjectDefinition of filteredObjectDefinitions || []) {
            const lookUpFieldOptions = filteredObjectDefinition.fields.filter(
                (field) => field.type === FieldType.LOOK_UP && field.relatedObjectType === objectDefinition?.namePlural
            );

            options.push({
                label: filteredObjectDefinition.label,
                value: filteredObjectDefinition.namePlural,
                uuid: filteredObjectDefinition.namePlural,
                options: lookUpFieldOptions.map((field) => {
                    return {
                        label: field.label,
                        value: field.name,
                        uuid: field.name,
                    };
                }),
            });
        }

        return options;
    };

    const mroOptions = buildMROOptions();

    return {
        columns,
        rows,
        control,
        getValues,
        isDirty,
        isValid,
        handleSubmit,
        confirmationDialogOpen,
        closeConfirmationDialog,
        saveChanges,
        savingChanges,
        resetState,
        discardChangesFromRouteBlockerPopup,
        handleInputChange,
        handleMultiPicklistChange,
        handleColumnsVisibilityChange,
        handleOnColumnsVisibilityEditorClick,
        addField,
        deleteField,
        reorder,
        fields,
        scrollingContainerRef,
        fieldsParametersTitleRef,
        tableContainerRef,
        tableScrollOffset,
        showColumnsVisibilityEditor,
        buildLookUpOptions,
        mroOptions,
    };
};

export type ObjectDefinitionContentControllerType = typeof useObjectDefinitionContentController;
