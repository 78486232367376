import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { Dialog, LABEL_PLACEMENT, SWITCH_SEVERITY, ToggleSwitch } from 'design-system';
import { Box } from '@mui/material';

type ModalSelectorObject = {
    id: string;
    primaryText: string;
    secondaryText: string;
    isExcluded: boolean;
};

export type { ModalSelectorObject };

export interface UniversalModalSelectorProps {
    visible: boolean;
    onConfirm: () => void;
    onClose: () => void;
    objects: ModalSelectorObject[];
    selectedObjectIds: string[];
    loading: boolean;
    handleOnObjectClick: (id: string) => void;
    handleOnConfirm: () => void;
    modalTitle: string;
    modalSubtitle?: string;
    objectDescription?: string;
    uncheckedLabel: string;
    checkedLabel: string;
    primaryButtonCaption: string;
    secondaryButtonCaption?: string;
    secondaryButtonAction?: () => void;
    searchBar?: boolean;
    searchValue?: string;
    setSearchValue?: (value: string) => void;
}

const StyledList = styled(List)`
    &.MuiList-root {
        padding: 0;
    }
`;

const StyledListItem = styled(ListItem)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
`;

const StyledListHeader = styled(ListItem)`
    background-color: ${({ theme }) => theme.palette.Basic.default_10};
`;

const ModalSelector = ({
    visible,
    onConfirm,
    onClose,
    objects,
    selectedObjectIds,
    loading,
    handleOnObjectClick,
    handleOnConfirm,
    modalTitle,
    modalSubtitle,
    objectDescription,
    uncheckedLabel,
    checkedLabel,
    primaryButtonCaption,
    secondaryButtonCaption,
    secondaryButtonAction,
    searchBar,
    searchValue,
    setSearchValue,
}: UniversalModalSelectorProps) => {
    const [toggleStates, setToggleStates] = useState<{ [key: string]: boolean }>({});
    const [selectedCount, setSelectedCount] = useState<number>(selectedObjectIds.length);

    useEffect(() => {
        const initialToggleStates = objects.reduce(
            (acc, object) => {
                acc[object.id] = selectedObjectIds.includes(object.id) || object.isExcluded;
                return acc;
            },
            {} as { [key: string]: boolean }
        );
        setToggleStates(initialToggleStates);
    }, [objects, selectedObjectIds]);

    const handleToggleChange = (id: string, checked: boolean) => {
        setToggleStates((prevState) => ({
            ...prevState,
            [id]: checked,
        }));
        handleOnObjectClick(id);
        setSelectedCount((prevCount) => (checked ? prevCount + 1 : prevCount - 1));
    };

    const content = (
        <StyledList>
            {objectDescription && (
                <StyledListHeader>
                    <ListItemText
                        primary={objectDescription}
                        primaryTypographyProps={{ style: { fontWeight: 'bold' } }}
                    />
                </StyledListHeader>
            )}
            {objects.map((object) => (
                <Fragment key={object.id}>
                    <StyledListItem>
                        <ListItemText primary={object.primaryText} secondary={object.secondaryText} />
                        <Box>
                            <ToggleSwitch
                                checked={toggleStates[object.id] || false}
                                setChecked={(checked) => handleToggleChange(object.id, checked)}
                                checkedLabel={object.isExcluded ? 'Already integrated with Badger' : checkedLabel}
                                uncheckedLabel={uncheckedLabel}
                                disabled={object.isExcluded}
                                labelPlacement={LABEL_PLACEMENT.START}
                                severity={SWITCH_SEVERITY.DEFAULT}
                            />
                        </Box>
                    </StyledListItem>
                    <Divider variant="fullWidth" component="li" />
                </Fragment>
            ))}
        </StyledList>
    );

    return (
        <Dialog
            title={modalTitle}
            subtitle={modalSubtitle}
            searchBar={searchBar}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            content={content}
            primaryButtonCaption={primaryButtonCaption}
            onPrimaryButtonClick={() => {
                handleOnConfirm();
                onConfirm();
            }}
            secondaryButtonCaption={
                secondaryButtonCaption && secondaryButtonAction ? secondaryButtonCaption : undefined
            }
            onSecondaryButtonClick={secondaryButtonAction && secondaryButtonCaption ? secondaryButtonAction : undefined}
            onClose={onClose}
            open={visible}
            loading={loading}
            primaryButtonDisabled={selectedCount === 0 || loading}
        />
    );
};

export default ModalSelector;
