import { styled } from 'styled-components';
import { ObjectParametersControllerType } from './objectParametersController';
import { loadFontAttributes } from 'design-system';
import MultiPicklistTypeField from '../MultiPicklistField/MultiPicklistField';
import PicklistTypeField from '../ObjectDefinitionsTable/PicklistTypeField/PicklistTypeField';
import { SortingOrderDirectionType } from '../../../domain/CompanyDefinition/CompanyDefinition';
import { Field } from '../defs';
import {
    HandleInputChange,
    ObjectDefinitionContentControllerType,
} from '../ObjectDefinitionContent/objectDefinitionContentController';
import BooleanField from '../ObjectDefinitionsTable/BooleanField/BooleanField';
import MainRelatedObjectType from '../ObjectDefinitionsTable/MainRelatedObjectType/MainRelatedObjectType';

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

const Label = styled.div`
    width: 144px;
    min-width: 144px;
    padding: 10px;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.bold)};
`;

const Content = styled.div`
    display: flex;
    flex: 1;
`;

const ContentShort = styled(Content)`
    max-width: 200px;
    min-width: 200px;
`;

const ContentLarge = styled(Content)`
    max-width: 579px;
`;

const InnerContainer = styled.div`
    width: fit-content;
`;

const Container = styled.div`
    width: 100%;
    background-color: ${({ theme }) => theme.palette.Basic.white};
`;

const StyledBooleanField = styled(BooleanField)`
    margin-left: -9px;
`;

interface props {
    useController: ObjectParametersControllerType;
    useObjectDefinitionContentController: ObjectDefinitionContentControllerType;
    fields: Field[];
    formControl: any;
    getValues: any;
    handleInputChange: HandleInputChange;
    handleMultiPicklistChange: (attribute: string) => void;
}

const ObjectParameters = ({
    formControl,
    getValues,
    fields,
    useController,
    useObjectDefinitionContentController,
    handleInputChange,
    handleMultiPicklistChange,
}: props) => {
    const { options, mainAddressOptions } = useController(fields);
    const { mroOptions } = useObjectDefinitionContentController();
    return (
        <Container>
            <InnerContainer>
                <FlexRow>
                    <Label>Title*</Label>
                    <ContentLarge>
                        <MultiPicklistTypeField
                            name="title"
                            options={options}
                            control={formControl}
                            rules={{ required: true }}
                            disabled={false}
                            handleChange={() => handleMultiPicklistChange('displayType')}
                        />
                    </ContentLarge>
                </FlexRow>
                <FlexRow>
                    <Label>Subtitle</Label>
                    <ContentLarge>
                        <MultiPicklistTypeField
                            name="subtitle"
                            options={options}
                            control={formControl}
                            rules={{}}
                            disabled={false}
                            handleChange={() => handleMultiPicklistChange('displayType')}
                        />
                    </ContentLarge>
                </FlexRow>
                <FlexRow>
                    <FlexColumn>
                        <FlexRow>
                            <Label>Sorting Order*</Label>
                            <ContentShort>
                                <PicklistTypeField
                                    options={options}
                                    name="sortingOrder"
                                    control={formControl}
                                    disabled={false}
                                    rules={{ required: true }}
                                    handleChange={() => handleMultiPicklistChange('sortingOrder')}
                                />
                            </ContentShort>
                        </FlexRow>
                    </FlexColumn>
                    <FlexColumn>
                        <FlexRow>
                            <Label>Order Direction*</Label>
                            <ContentShort>
                                <PicklistTypeField
                                    options={[
                                        {
                                            label: SortingOrderDirectionType.ASCENDING,
                                            value: SortingOrderDirectionType.ASCENDING,
                                            uuid: SortingOrderDirectionType.ASCENDING,
                                        },
                                        {
                                            label: SortingOrderDirectionType.DESCENDING,
                                            value: SortingOrderDirectionType.DESCENDING,
                                            uuid: SortingOrderDirectionType.DESCENDING,
                                        },
                                    ]}
                                    name="orderDirection"
                                    control={formControl}
                                    disabled={false}
                                    rules={{ required: true }}
                                    handleChange={(value: string) => {
                                        const sortingOrderFieldValues = getValues('sortingOrder');
                                        const newValue = sortingOrderFieldValues ? sortingOrderFieldValues[0] : '';
                                        handleInputChange(
                                            newValue,
                                            'sortingOrderDirection',
                                            value as SortingOrderDirectionType
                                        );
                                    }}
                                />
                            </ContentShort>
                        </FlexRow>
                    </FlexColumn>
                </FlexRow>
                <FlexRow>
                    <Label>Search criteria*</Label>
                    <ContentLarge>
                        <MultiPicklistTypeField
                            name="searchCriteria"
                            options={options}
                            control={formControl}
                            rules={{ required: true }}
                            disabled={false}
                            handleChange={() => handleMultiPicklistChange('isSearchable')}
                        />
                    </ContentLarge>
                </FlexRow>
                <FlexRow>
                    <Label>Mapping address</Label>
                    <ContentShort>
                        <PicklistTypeField
                            options={mainAddressOptions}
                            name="mainAddress"
                            control={formControl}
                            disabled={false}
                            rules={{}}
                            handleChange={() => handleMultiPicklistChange('isMain')}
                        />
                    </ContentShort>
                </FlexRow>
                <FlexRow>
                    <Label>Is root</Label>
                    <ContentShort>
                        <BooleanField
                            name="isRoot"
                            control={formControl}
                            rules={{}}
                            disabled={false}
                            handleChange={(value: boolean) => {
                                handleInputChange('isRoot', 'isRoot', value);
                            }}
                        />
                    </ContentShort>
                </FlexRow>
                {mroOptions.length > 0 && (
                    <MainRelatedObjectType
                        mroOptions={mroOptions}
                        name="mainRelatedObject"
                        control={formControl}
                        disabled={false}
                        rules={{ required: true }}
                        handleChange={() => handleMultiPicklistChange('mainRelatedObject')}
                    />
                )}
            </InnerContainer>
        </Container>
    );
};

export default ObjectParameters;
