import ModalSelector, { ModalSelectorObject } from '../../../common/components/ModalSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    companyObjectTypesRetrievalRequested,
    selectCompanyCrmObjectTypes,
    selectIsLoadingCompanyCrmObjectTypes,
} from '../../../store/slices/companyCrmObjectTypesSlice';
import { useEffect } from 'react';

export const ObjectDefinitionsSelectorModal = ({ isOpen, close }: { isOpen: boolean; close: () => void }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector(selectIsLoadingCompanyCrmObjectTypes);
    const objectTypes = useAppSelector(selectCompanyCrmObjectTypes);

    useEffect(() => {
        if (isOpen && !isLoading && !objectTypes.length) {
            dispatch(companyObjectTypesRetrievalRequested());
        }
    }, [isOpen, dispatch, isLoading, objectTypes.length]);

    const modalSelectorObjects = objectTypes.map(
        (objectType, key) =>
            ({
                id: String(key),
                primaryText: objectType.name,
                secondaryText: objectType.label,
                isExcluded: false,
            }) as ModalSelectorObject
    );

    return (
        <ModalSelector
            visible={isOpen}
            onConfirm={() => {
                alert('This functionality is not yet implemented. Nothing happened');
            }}
            onClose={close}
            objects={modalSelectorObjects}
            selectedObjectIds={[]}
            loading={isLoading}
            handleOnObjectClick={() => {}}
            handleOnConfirm={() => {}}
            modalTitle={'Retrieve objects from CRM'}
            modalSubtitle={'Select objects'}
            uncheckedLabel={''}
            checkedLabel={''}
            primaryButtonCaption={'Confirm'}
            secondaryButtonCaption={'Back'}
            secondaryButtonAction={close}
        />
    );
};
