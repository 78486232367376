import styled from 'styled-components';
import { ObjectDefinitionsTableControllerType } from './objectDefinitionsTableController';
import {
    Alignments,
    Button,
    BUTTON_SEVERITY_LEVELS,
    ButtonTypes,
    Icon,
    IconNames,
    loadFontAttributes,
    Popover,
} from 'design-system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import NumericField from './NumericField/NumericField';
import PicklistTypeField from './PicklistTypeField/PicklistTypeField';
import BooleanField from './BooleanField/BooleanField';
import { AddressAccuracyTypeOptions, ColumnType, PicklistOptions, Row, ROW_COLUMN_SEPARATOR } from '../defs';
import TextTypeField from './TextTypeField/TextTypeField';
import PicklistValuesTypeField from './PicklistValuesTypeField/PicklistValuesTypeField';
import PickListValuesEditor from '../PicklistValuesEditor/PicklistValuesEditor';
import {
    HandleColumnsVisibilityChange,
    HandleInputChange,
} from '../ObjectDefinitionContent/objectDefinitionContentController';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useEffect, useMemo, useRef } from 'react';
import LookUpField from './LookUpField';
import MultiPicklistTypeField from '../MultiPicklistField/MultiPicklistField';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    background-color: ${({ theme }) => theme.palette.Basic.lighter};
`;

const StyledTableContainer = styled(TableContainer)<any>`
    & .MuiTableCell-body {
        padding: 0 16px !important;
    }

    & .MuiInputBase-root {
        margin: 8px 0;
    }
`;

const StyledTableHead = styled(TableHead)<any>`
    background-color: ${({ theme }) => theme.palette.Background.table.header.default} !important;

    &.sticky {
        position: sticky;
        z-index: 1000;
    }
`;

const StyledTableCell = styled(TableCell)<any>`
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    width: ${({ $width }) => $width}px !important;
    min-width: ${({ $width }) => $width}px !important;
    text-align: ${({ $align }) => $align} !important;
`;

const HeaderTableCell = styled(StyledTableCell)<any>`
    height: 36px;
    background-color: ${({ theme }) => theme.palette.Background.table.header.default} !important;
    ${({ theme }) => loadFontAttributes(theme.typography.Small.bold)};
`;

const StickyHeaderTableCell = styled(HeaderTableCell)<any>`
    position: sticky;
    left: ${({ $left }) => $left}px;
    width: ${({ $width }) => $width}px !important;
    z-index: 900 !important;
`;

const BodyTableCell = styled(StyledTableCell)<any>`
    background-color: ${({ $exclusionCell }) => ($exclusionCell ? '#F9F4E9' : 'white')} !important;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
    position: relative;
    height: 60px;
`;

const StickyBodyTableCell = styled(BodyTableCell)<any>`
    position: sticky;
    left: ${({ $left }) => $left}px;
    z-index: 800 !important;
`;

const AddFieldContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 35px;
    margin: 10px 0;
`;

const DragIconContainer = styled.div`
    color: ${({ theme }) => theme.palette.Text.onSurface.high};
    min-width: 15px;
`;

const DroppableContainer = styled(Table)<any>`
    overflow-y: hidden;
    height: ${({ $height }) => $height}px;
`;

const ExclusionContainer = styled.span`
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    top: calc(50% - 10px);
    z-index: 1;
    font-weight: bold;
`;

export interface Props {
    columns: any[];
    rows: Row[] | undefined;
    addField: () => void;
    deleteField: (fieldId: string) => void;
    reorder: (from: number, to: number) => void;
    formControl: any;
    useController: ObjectDefinitionsTableControllerType;
    handleInputChange: HandleInputChange;
    handleColumnsVisibilityChange: HandleColumnsVisibilityChange;
    scrollOffset?: number | null;
    lookUpOptions:
        | {
              label: string;
              value: string;
              uuid: string;
          }[]
        | undefined;
}

const ObjectDefinitionTable = ({
    columns,
    rows,
    addField,
    deleteField,
    handleInputChange,
    handleColumnsVisibilityChange,
    reorder,
    formControl,
    useController,
    scrollOffset,
    lookUpOptions,
}: Props) => {
    const {
        handleOnPicklistClick,
        picklistFieldUuid,
        picklistValueFormName,
        pickListValuesEditorVisible,
        closePickListValuesEditor,
        onDragEnd,
    } = useController(reorder);

    const ref = useRef(null);
    const headerRef = useRef<HTMLElement>(null);

    useEffect(() => {
        const header = headerRef.current;
        if (header === null || scrollOffset === undefined || scrollOffset === null) return;

        if (scrollOffset > 0) {
            header.classList.add('sticky');
            header.style.top = `${scrollOffset}px`;
        } else {
            header.classList.remove('sticky');
        }
    }, [scrollOffset]);

    const renderTable = () => {
        const visibleColumns = columns.filter((column) => column.visible);
        return visibleColumns && rows ? (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided: any) => (
                        <DroppableContainer
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            $height={rows.length * 60 + 36}
                            stickyHeader
                            aria-label="sticky table"
                            size="small"
                        >
                            <StyledTableHead ref={headerRef} id="table-header" data-testid="table-header">
                                {renderHeader(visibleColumns)}
                            </StyledTableHead>
                            <TableBody>{renderRows(rows)}</TableBody>
                        </DroppableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        ) : null;
    };
    const cachedTable = useMemo(renderTable, [rows, columns]);

    if (!columns || !rows) {
        return null;
    }

    const getAlignment = (column: any) =>
        [ColumnType.BOOLEAN, ColumnType.SORTING_ORDER].includes(column.type) ? 'center' : 'left';

    const renderFieldTypeExtraContent = (row: any, column: any, name: string) => {
        switch (row.type) {
            case 'lookup':
                return (
                    <LookUpField
                        lookUpOptions={lookUpOptions || []}
                        name={`${row.uuid}${ROW_COLUMN_SEPARATOR}relatedObjectType`}
                        control={formControl}
                        disabled={row.id !== null}
                        rules={{
                            required: true,
                        }}
                        handleChange={(value: string) => handleInputChange(row.uuid, column.name, value)}
                    />
                );
            case 'address':
                return (
                    <MultiPicklistTypeField
                        name={`${row.uuid}${ROW_COLUMN_SEPARATOR}accuracyTypes`}
                        options={AddressAccuracyTypeOptions}
                        control={formControl}
                        rules={{ required: true }}
                        disabled={row.id !== null}
                    />
                );
            default:
                return null;
        }
    };

    const renderItem = (row: any, column: any, innerRef: any = null, dragHandleProps = {}) => {
        if (column.type !== ColumnType.ACTION && [undefined, null].includes(row[column.name])) {
            return null;
        }
        const name = `${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`;
        switch (column.type) {
            case ColumnType.BOOLEAN:
                return (
                    <BooleanField
                        name={name}
                        control={formControl}
                        disabled={!column.editable}
                        rules={{}}
                        handleChange={(value: boolean) => handleInputChange(row.uuid, column.name, value)}
                    />
                );
            case ColumnType.PICKLIST_LABELS_VALUES:
                return (
                    <PicklistValuesTypeField
                        name={name}
                        control={formControl}
                        disabled={!column.editable}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                            handleOnPicklistClick(event, row, column);
                        }}
                        rules={{
                            validate: {
                                required: (value: any) => {
                                    if (value.length === 0) {
                                        return false;
                                    }

                                    let emptyFields = false;
                                    value.forEach((item: any) => {
                                        if (!item.label || !item.value) {
                                            emptyFields = true;
                                        }
                                    });

                                    return !emptyFields;
                                },
                            },
                        }}
                    />
                );
            case ColumnType.NUMBER:
                return (
                    <NumericField
                        name={name}
                        control={formControl}
                        disabled={!column.editable}
                        rules={{ min: 0, required: true }}
                        handleChange={(value: number) => handleInputChange(row.uuid, column.name, value)}
                    />
                );
            case ColumnType.TEXT:
                return (
                    <TextTypeField
                        name={name}
                        control={formControl}
                        disabled={column.name === 'name' ? row.id !== null : !column.editable}
                        rules={{
                            required: true,
                            validate: {
                                duplicated: (value: any) => {
                                    if (column.name !== 'name') return true;
                                    if (value) {
                                        const duplicated = rows.filter(
                                            (r: Row) => r.name === value && r.uuid !== row.uuid
                                        );
                                        return duplicated.length === 0;
                                    }
                                },
                            },
                        }}
                        handleChange={(value: string) => handleInputChange(row.uuid, column.name, value)}
                    />
                );
            case ColumnType.PICKLIST:
                return (
                    <>
                        <PicklistTypeField
                            options={PicklistOptions[column.name] || []}
                            name={name}
                            control={formControl}
                            disabled={column.name === 'type' ? row.id !== null : !column.editable}
                            rules={{
                                required: column.name === 'type',
                            }}
                            handleChange={(value: string) => handleInputChange(row.uuid, column.name, value)}
                        />
                        {column.name === 'type' && renderFieldTypeExtraContent(row, column, name)}
                    </>
                );
            case ColumnType.ACTION:
                return (
                    <div style={{ width: '50px', margin: '0 auto' }}>
                        <Button
                            type={ButtonTypes.TEXT}
                            alignment={Alignments.ROW}
                            icon={<Icon icon={IconNames.REMOVE_BIN} />}
                            severity={BUTTON_SEVERITY_LEVELS.ALERT}
                            onClick={(e) => {
                                e.preventDefault();
                                deleteField(row.uuid);
                            }}
                        >
                            <></>
                        </Button>
                    </div>
                );
            case ColumnType.ORDER:
                return (
                    <DragIconContainer ref={innerRef} {...dragHandleProps}>
                        <Icon icon={IconNames.DRAG_HANDLE} width={14} height={24} />
                    </DragIconContainer>
                );
            default:
                return row[column.name];
        }
    };

    const getLeftPosition = (index: number) => {
        switch (index) {
            case 1:
                return columns[0].width;
            case 2:
                return columns[0].width + columns[1].width;
            default:
                return 0;
        }
    };

    const DraggableRow = ({ index, row, columns }: { index: number; row: Row; columns: any[] }) => (
        <Draggable key={index} draggableId={index.toString()} index={index} isDragDisabled={false}>
            {(provided: any) => {
                return (
                    <TableRow key={`row-${index}`} {...provided.draggableProps}>
                        {columns.map((column: any, colIndex: number) =>
                            colIndex > 2 ? (
                                <BodyTableCell
                                    $width={column.width}
                                    $align={getAlignment(column)}
                                    key={`${index}-${colIndex}`}
                                    $exclusionCell={['isMandatory', 'isViewOnly'].includes(column.name) as boolean}
                                >
                                    {renderItem(row, column, provided.innerRef, provided.dragHandleProps)}
                                    {column.name === 'isMandatory' && <ExclusionContainer>or</ExclusionContainer>}
                                </BodyTableCell>
                            ) : (
                                <StickyBodyTableCell
                                    $width={column.width}
                                    $align={getAlignment(column)}
                                    $left={getLeftPosition(colIndex)}
                                    key={`${index}-${colIndex}`}
                                >
                                    {renderItem(row, column, provided.innerRef, provided.dragHandleProps)}
                                </StickyBodyTableCell>
                            )
                        )}
                    </TableRow>
                );
            }}
        </Draggable>
    );

    const renderHeader = (columns: any[]) => {
        return (
            <TableRow key="header-row">
                {columns.map((column, index) =>
                    index > 2 ? renderColumnHeader(column) : renderStickyColumnHeader(column, index)
                )}
            </TableRow>
        );
    };

    const renderColumnHeader = (column: any) => {
        return (
            <HeaderTableCell
                $width={column.width}
                $align={getAlignment(column)}
                key={`col-${column.name}`}
                ref={column.name === 'pickList' ? ref : null}
            >
                {column.label}
            </HeaderTableCell>
        );
    };

    const renderStickyColumnHeader = (column: any, index: number) => {
        return (
            <StickyHeaderTableCell
                $width={column.width}
                $align={getAlignment(column)}
                $left={getLeftPosition(index)}
                key={`col-${column.name}`}
            >
                {column.label}
            </StickyHeaderTableCell>
        );
    };

    const renderRows = (rows: Row[]) => {
        const visibleColumns = columns.filter((column) => column.visible);
        return rows.map((row: any, rowIndex: number) => (
            <DraggableRow index={rowIndex} row={row} columns={visibleColumns} key={`draggable_wrapper_${row.uuid}`} />
        ));
    };

    return (
        <Container>
            <StyledTableContainer>{cachedTable}</StyledTableContainer>

            <AddFieldContainer>
                <Button
                    type={ButtonTypes.TEXT}
                    alignment={Alignments.ROW}
                    icon={<Icon icon={IconNames.ADD_FILLED} />}
                    onClick={(e) => {
                        e.preventDefault();
                        addField();
                    }}
                >
                    Add Field
                </Button>
            </AddFieldContainer>

            {pickListValuesEditorVisible && (
                <Popover open={true} anchorEl={ref.current} onClose={closePickListValuesEditor} placement="bottom-end">
                    <PickListValuesEditor
                        name={picklistValueFormName}
                        control={formControl}
                        rules={{}}
                        handleChange={(value: object) => handleInputChange(picklistFieldUuid, 'options', value)}
                    />
                </Popover>
            )}
        </Container>
    );
};

export default ObjectDefinitionTable;
