import { DisplayType, SortingOrderDirectionType } from '../../../domain/CompanyDefinition/CompanyDefinition';

const genericDefaultFields = {
    id: '',
    name: '',
    label: '',
    isMandatory: false,
    isSearchable: false,
    isFilterable: false,
    isColorizable: false,
    isViewOnly: false,
    displayOrder: 0,
    sortingOrder: 0,
    sortingOrderDirection: SortingOrderDirectionType.ASCENDING,
    displayType: DisplayType.AS_DETAILS,
};

export const defaultFields: any = {
    text: {
        ...genericDefaultFields,
        type: 'text',
        maxLength: 10000,
        isTextArea: false,
    },
    datetime: {
        ...genericDefaultFields,
        type: 'datetime',
    },
    picklist: {
        ...genericDefaultFields,
        type: 'picklist',
        options: [],
    },
    address: {
        ...genericDefaultFields,
        type: 'address',
        isMain: false,
        accuracyTypes: [],
    },
    numeric: {
        ...genericDefaultFields,
        type: 'numeric',
        decimals: 0,
        minValue: null,
        maxValue: null,
    },
    lookup: {
        ...genericDefaultFields,
        type: 'lookup',
        relatedObjectType: '',
    },
};

export const propertyMapper: any = {
    pickList: 'options',
};

export const getValidProperties = (fieldType: string) => Object.keys(defaultFields[fieldType]);
