export const enum FieldType {
    TEXT = 'text',
    NUMBER = 'numeric',
    PICK_LIST = 'picklist',
    ADDRESS = 'address',
    DATE_TIME = 'datetime',
    LOOK_UP = 'lookup',
}

export const enum DisplayType {
    AS_TITLE = 'AS_TITLE',
    AS_SUBTITLE = 'AS_SUBTITLE',
    AS_DETAILS = 'AS_DETAIL',
}

export const enum SortingOrderDirectionType {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

export const enum AccuracyType {
    ROOFTOP = 'ROOFTOP',
    RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
    GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
    APPROXIMATE = 'APPROXIMATE',
}

export type BaseField = {
    [key: string]: any;
    id: string;
    name: string;
    label: string;
    isMandatory: boolean;
    isSearchable: boolean;
    isFilterable: boolean;
    isColorizable: boolean;
    isViewOnly: boolean;
    displayOrder: number;
    sortingOrder: number;
    sortingOrderDirection: SortingOrderDirectionType;
    displayType: DisplayType;
};

export type TextField = BaseField & {
    type: FieldType.TEXT;
    isTextArea: boolean;
    maxLength: number;
};

export type NumericField = BaseField & {
    type: FieldType.NUMBER;
    minValue: number;
    maxValue: number;
    decimals: number;
};

export type PickListOption = {
    id: string;
    label: string;
    value: string;
    order: number;
};

export type PickListField = BaseField & {
    type: FieldType.PICK_LIST;
    options: PickListOption[];
};

export type AddressField = BaseField & {
    type: FieldType.ADDRESS;
    isMain: boolean;
    accuracyTypes: AccuracyType[];
};

export type DateTimeField = BaseField & {
    type: FieldType.DATE_TIME;
};

export type LookUpField = BaseField & {
    type: FieldType.LOOK_UP;
    relatedObjectType: string;
};

export type MainRelatedObject = {
    relatedObjectType: string;
    lookUpField: string;
};
export type Field = TextField | NumericField | PickListField | AddressField | DateTimeField | LookUpField;

export type ObjectDefinition = {
    id: string;
    name: string;
    namePlural: string;
    label: string;
    labelPlural: string;
    isRoot: boolean;
    order: number;
    mainRelatedObject?: MainRelatedObject;
};

export type BadgerObjectDefinition = ObjectDefinition & {
    fields: Field[];
};

export type LinkDefinition = {
    id: string;
    order: number;
    sourceDefinitionNamePlural: string;
    targetDefinitionNamePlural: string;
    isTargeMain: boolean;
};

export type CompanyDefinition = {
    badgerObjectDefinitions: BadgerObjectDefinition[];
    linkDefinitions: LinkDefinition[];
};

export default CompanyDefinition;
